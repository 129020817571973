/** @jsx jsx */
import { Box, jsx, Flex, Text, Link } from 'theme-ui'

const Social = () => (
  <Box>
    <Box
      sx={{
        color: 'text',
        p: 4,
        textAlign: 'center',
      }}
    >
      <Text
        as="h2"
        sx={{ mb: 3, fontWeight: 700, fontSize: [5, 5, 5, 6, 7, 7] }}
      >
        CONTACT US
      </Text>
      <Flex sx={{ justifyContent: 'center', alignItems: 'center', mb: 3 }}>
        <img
          alt="Instagram"
          src="https://static.wixstatic.com/media/11062b_55e4be1e75564866b6c28290f9a9d271~mv2.png/v1/fill/w_90,h_90,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/11062b_55e4be1e75564866b6c28290f9a9d271~mv2.png"
          sx={{
            width: ['45px', '90px'],
            height: ['45px', '90px'],
            objectFit: 'cover',
          }}
        />
      </Flex>
      <Text as="p" sx={{ fontSize: [2, 2, 3, 3, 3, 4], mb: 2 }}>
        info@ukmeta.jp
      </Text>
      <Text as="p" sx={{ fontSize: [2, 2, 3, 3, 3, 4], mb: 2 }}>
        お気軽にお問合せください
      </Text>
      {/* <Text as="p" sx={{ mb: 1 }}>
        お問い合わせ:
      </Text>
      <Text as="p">採用について:</Text> */}
    </Box>
  </Box>
)

export default Social
