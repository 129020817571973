/** @jsx jsx */
import { jsx } from 'theme-ui'
import Layout from '../components/layout'
import Hero from '../components/Home/hero'
import Projects from '../components/Home/projects'
import About from '../components/Home/about'
import { Box } from 'theme-ui'
import Footer from '../components/footer'
import SocialMedia from '../components/Home/social-media'

const Cara = () => (
  <Layout>
    <Box
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        overflow: 'hidden scroll',
      }}
    >
      <Box
        sx={{
          overflow: 'hidden',
          position: 'absolute',
          width: '100%',
          height: '650vh',
        }}
      >
        <Hero />
        <Projects />
        <About offset={3} factor={1} />
        <SocialMedia />
        <Footer />
      </Box>
    </Box>
  </Layout>
)

export default Cara
