/** @jsx jsx */
import { jsx } from 'theme-ui'

type DividerProps = {
  children?: React.ReactNode
  bg?: string
  fill?: string
  clipPath?: string
  className?: string
}

const Divider = ({
  bg = ``,
  fill = ``,
  clipPath = ``,
  children = null,
  className = ``,
}: DividerProps) => (
  <div
    sx={{
      position: 'absolute',
      inset: '0px',
      backgroundSize: 'auto',
      backgroundRepeat: 'no-repeat',
      willChange: 'transform',
      width: '100%',
      height: '999px',
      transform: 'translate3d(0px, 0px, 0px)',
      background: bg,
      backgroundColor: bg,
      '#contact-wave': {
        color: fill,
        fill: `currentColor`,
      },
      clipPath,
    }}
    className={className}
  >
    {children}
  </div>
)

export default Divider
