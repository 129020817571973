/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Box, Flex, Heading, Paragraph, Divider } from 'theme-ui'
import { keyframes } from '@emotion/react'
import React from 'react'

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`

const slideIn = keyframes`
  from { transform: translateX(-20px); }
  to { transform: translateX(0); }
`

function padNumber(num: number): string {
  return num.toString().padStart(2, '0')
}

const Product: React.FC<{
  name: string
  description: string
  index: number
}> = ({ name, description, index }) => (
  <Flex
    sx={{
      flexDirection: 'column',
      opacity: 0,
      animation: `${fadeIn} 1s ease-in ${
        index * 0.7
      }s forwards, ${slideIn} 1s ease-in ${index * 0.7}s forwards`,
    }}
  >
    <Box sx={{ fontSize: ['1rem', 3, 4] }}>{padNumber(index)}</Box>
    <Heading
      as="h3"
      sx={{
        fontSize: [6, 6, 7, 7, 7, 8],
        fontWeight: 700,
        color: 'highlight',
        mb: 1,
      }}
    >
      {name}
    </Heading>
    <Paragraph sx={{ fontSize: [2, 2, 3, 3, 3, 4], color: 'muted', mb: 3 }}>
      {description}
    </Paragraph>
    <Divider sx={{ bg: 'muted', margin: '1rem 0' }} />
  </Flex>
)

const OurProducts: React.FC = () => {
  return (
    <Box
      sx={{
        bg: 'linear-gradient(130deg, #667eea, #764ba2)',
        color: 'white',
        p: [1, 2, 4, 4, 4, 4],
        overflow: 'hidden',
      }}
    >
      <Heading as="h1" sx={{ mb: 4 }}>
        Our Products
      </Heading>
      <Box sx={{ maxWidth: 'container', mx: 'auto' }}>
        <Product
          name="Engibiz"
          description="あなたの成功のために文化を創造するエンジニアマッチング専門エージェント"
          index={1}
        />
        <Product
          name="YTStats"
          description="YOUTUBE分析ツールの提供"
          index={2}
        />
      </Box>
    </Box>
  )
}

export default OurProducts

// // 원이 나타나는 애니메이션 정의
// const fadeIn2 = keyframes`
//   from { opacity: 0; transform: scale(0.5); }
//   to { opacity: 1; transform: scale(1); }
// `

// const Product2 = ({ children }) => {
//   // 마우스 오버 상태를 관리하는 state
//   const [isHovered, setIsHovered] = React.useState(false)

//   return (
//     <Box
//       sx={{
//         position: 'relative',
//         display: 'inline-block',
//         cursor: 'pointer',
//         '&:hover': {
//           '.circle': {
//             animation: `${fadeIn2} 0.5s ease-in-out forwards`,
//           },
//         },
//       }}
//       onMouseEnter={() => setIsHovered(true)}
//       onMouseLeave={() => setIsHovered(false)}
//     >
//       <Box as="span" sx={{ fontSize: 6, fontWeight: 'bold' }}>
//         {children}
//       </Box>
//       <Box
//         className="circle"
//         sx={{
//           position: 'absolute',
//           top: '50%',
//           left: '50%',
//           transform: 'translate(-50%, -50%)',
//           width: '100px',
//           height: '100px',
//           borderRadius: '50%',
//           backgroundColor: 'purple',
//           opacity: 0,
//           pointerEvents: 'none', // 마우스 이벤트가 원에 영향을 주지 않도록 설정
//         }}
//         style={{
//           // 애니메이션 상태에 따라 스타일 동적 적용
//           visibility: isHovered ? 'visible' : 'hidden',
//         }}
//       />
//     </Box>
//   )
// }
