/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { useState } from 'react'
import { Box, Flex, Heading } from 'theme-ui'

interface ListItemProps {
  text: { title: string; description: string; image: string }
  isPrimary?: boolean
}

const ListItem: React.FC<ListItemProps> = ({ text, isPrimary = false }) => {
  const [onView, setOnView] = useState<boolean>(false)
  const toggleView = () => setOnView(!onView)
  return (
    <Flex
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        alignItems: 'center',
        borderBottom: '1px solid',
        borderColor: 'heading',
        p: '1rem 0',
        color: 'heading',
        fontSize: [2, 3, 5, 6, 7, 7],
        flexDirection: `column`,
      }}
    >
      <Heading
        as="h3"
        sx={{
          fontWeight: 700,
          justifyContent: 'flex-end',
          textAlign: 'end',
          width: '100%',
          cursor: 'pointer',
          transition: 'transform 0.3s ease',
          '&:hover': {
            transform: 'scale(1.02)',
          },
        }}
        onClick={toggleView}
      >
        <span sx={{ color: 'textMuted' }}>{text.title.slice(0, 1)}</span>
        {text.title.slice(1)}

        <span
          sx={{
            ml: [1, 2, 4],
            fontWeight: 200,
            color: 'heading',
          }}
        >
          {onView ? '-' : '+'}
        </span>
      </Heading>
      {onView && (
        <React.Fragment>
          <img
            src={text.image}
            alt="image"
            sx={{
              objectFit: 'cover',
              width: '330px',
              height: '220px',
              maxWidth: '100%',
              margin: '40px 0 32px auto',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          />
          <Box
            as="div"
            sx={{
              width: '100%',
              fontWeight: 200,
              color: 'heading',
              fontSize: '25px',
              textAlign: 'end',
            }}
          >
            {text.description}
          </Box>
        </React.Fragment>
      )}
    </Flex>
  )
}

const ListContainer: React.FC = () => {
  const listItems = [
    {
      title: '#UKMETA_IS_HIRING',
      image: 'hiring.webp',
      description: `必須要件以下すべてのご経験をお持ちの方からのご応募をおまちしています！
      当社ダイバーシティ＆インクルージョンに取り組んでいます。
      `,
    },
    {
      title: '#NEWOFFICE',
      image: 'office.jpg',
      description: `〒160-0023 東京都新宿区西新宿7-7-26 ワコーレ新宿第一ビル 2階 E`,
    },
    {
      title: `#${new Date().getFullYear()}_TECHMEETUP`,
      image: 'tech-seminar.jpg',
      description: `スタートアップとのオープンイノベーションを通じて、持続可能な都市を実現するためのイベントです。`,
    },
  ]
  return (
    <Box
      sx={{
        color: 'text',
        width: '100%',
      }}
    >
      {listItems.map((item, index) => (
        <ListItem key={index} text={item} isPrimary={index === 0} />
      ))}
    </Box>
  )
}

export default ListContainer
